import React, { useState, useContext } from "react"

import { SEO } from "../components/seo"
import { Heading, Box, Label, Input, Button, Message, Container, Grid } from '@theme-ui/components'
import { FirebaseContext } from "gatsby-plugin-firebase"
import { navigate } from 'gatsby';

interface SignInErr {
  code: string
  message: string
}

const SignIn = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState<SignInErr | undefined>(undefined)
  const firebase = useContext(FirebaseContext) as any

  return (
    <>
      <SEO title="App: Private" />
      <Container sx={{ py: 5 }}>
        <Grid columns={[1, 1, 3]}>
          <Box />
          <Box>
            <Heading>Sign in</Heading>
            {error && (
              <Message>
                {error.message}
              </Message>
            )}
            <form onSubmit={(e) => {
              e.preventDefault();
              if (!firebase) {
                return
              } else {

                firebase
                  .auth()
                  .signInWithEmailAndPassword(email, password)
                  .then(() => navigate("/app/"))
                  .catch((err: any) => setError(err))
              }
              console.log(email, password)
            }}>
              <Box my={3}>
                <Label>
                  Email
                </Label>
                <Input type="email" value={email} onChange={(e: any) => setEmail(e.target!.value)} name="email" />
              </Box>
              <Box mb={3}>
                <Label>
                  Password
                </Label>
                <Input type="password" value={password} onChange={(e: any) => setPassword(e.target!.value)} name="password" />
              </Box>
              <Box mb={3}>
                <Button type="submit">Sign in</Button>
              </Box>
            </form>
          </Box>
          <Box />
        </Grid>
      </Container>
    </>
  )
}

export default SignIn
